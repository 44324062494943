let mybutton = document.getElementById("top-btn");

window.onscroll = function () { scrollFunction() };

function scrollFunction() {
  if (window.innerWidth <= 768) {
    if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  } else {
    mybutton.style.display = "none";
  }
}
