document.addEventListener('turbo:load', function () {
	const anchors = document.querySelectorAll('a[href="#footer"],a[href="#our_services"],a[href="#images_block"],a[href="#reviews"],a[href="#brands"],a[href="#faq"],a[href="#page_top"]');

	for (let anchor of anchors) {
		anchor.addEventListener('click', function (e) {
			e.preventDefault();
			const blockID = anchor.getAttribute('href').substr(1);

			document.getElementById(blockID).scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		});
	}
});