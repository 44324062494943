document.addEventListener('turbo:load', function () {
	var openMenu = document.querySelector('.menu-open');
	var menu = document.querySelector('.menu');
	var darken = document.querySelector('.darken');

	function toggleMenu() {
		menu.classList.toggle('active');
		openMenu.classList.toggle('active');
		if (menu.classList.contains('active')) {
			darken.style.display = 'block';
		} else {
			darken.style.display = 'none';
		}
	}
	openMenu.addEventListener('click', function (event) {
		toggleMenu();
		event.stopPropagation();
	});

	menu.addEventListener('click', function (event) {
		event.stopPropagation();
	});

	document.addEventListener('click', function (event) {
		var isClickInsideMenu = menu.contains(event.target);

		if (!isClickInsideMenu && menu.classList.contains('active')) {
			toggleMenu();
		}
	});
});

