import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(PixiPlugin, MotionPathPlugin, ScrollTrigger);
document.addEventListener('turbo:load', function () {

	
	const animatedNumber = document.querySelectorAll(".number");

	animatedNumber.forEach((num) => {
		const animation = gsap.to(num, {
			color: "#E2830F",
			scale: 1.3,
			duration: 0.5,
			scrollTrigger: {
				trigger: num,
				start: "center 60%",
				toggleActions: "play none none none"
			},
			onComplete: () => {
				gsap.to(num, {
					duration: 0.5,
					scale: 1,
				});
			}
		});

		const parent = num.closest(".stages-item");
		const title = parent.querySelector(".stages-title");
		const bodyText = parent.querySelector(".body-text-regular");

		gsap.to(title, {
			color: "#000",
			duration: 0.5,
			scrollTrigger: {
				trigger: num,
				start: "center 60%",
				toggleActions: "play none none none"
			}
		});

		gsap.to(bodyText, {
			color: "#000",
			duration: 0.5,
			scrollTrigger: {
				trigger: num,
				start: "center 60%",
				toggleActions: "play none none none"
			}
		});
	});

	const animatedLines = document.querySelectorAll(".line");

	animatedLines.forEach((line) => {
		const animation = gsap.to(line, {
			backgroundColor: "#E2830F",
			duration: 0.5,
			scrollTrigger: {
				trigger: line,
				start: "center 57%",
				toggleActions: "play none none none"
			}
		});

		const parent = line.closest(".stages-item");
		const title = parent.querySelector(".stages-title");
		const bodyText = parent.querySelector(".body-text-regular");

		gsap.to(title, {
			color: "#000",
			duration: 0.5,
			scrollTrigger: {
				trigger: line,
				start: "center 57%",
				toggleActions: "play none none none"
			}
		});

		gsap.to(bodyText, {
			color: "#000",
			duration: 0.5,
			scrollTrigger: {
				trigger: line,
				start: "center 57%",
				toggleActions: "play none none none"
			}
		});
	});

})