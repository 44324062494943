document.addEventListener('DOMContentLoaded', function () {
	var inputs = document.querySelectorAll('input[required], textarea[required]');
	var submitBtn = document.getElementById('submitBtn');

	function checkForm() {
		var isValid = true;
		inputs.forEach(function (input) {
			if (!input.value.trim()) {
				isValid = false;
			}
		});
		if (isValid) {
			submitBtn.classList.add('valid');
		} else {
			submitBtn.classList.remove('valid');
		}
	}

	inputs.forEach(function (input) {
		input.addEventListener('input', checkForm);
	});
});


// Отримуємо посилання на елемент <p>
var pElement = document.querySelector('.file-input-wrapper p');

// Отримуємо посилання на елемент <input type="file">
var fileInput = document.querySelector('.file-input-wrapper input[type="file"]');

// Додаємо обробник події для зміни значення файлового вводу
fileInput.addEventListener('change', function () {
	// Перевіряємо, чи були вибрані файли
	if (fileInput.files.length > 0) {
		// Отримуємо імена всіх вибраних файлів
		var fileNames = Array.from(fileInput.files).map(function (file) {
			return file.name;
		});

		// Об'єднуємо імена файлів через "/"
		var combinedNames = fileNames.join(" / ");

		// Змінюємо текст в <p> на об'єднані імена файлів
		pElement.textContent = combinedNames;
	} else {
		// Якщо файли не вибрані, змінюємо текст на початкове значення
		pElement.textContent = "Choose a photo";
	}
});
